import './App.css'

import CustomNavbar from './componts/Navbar/Navbar';
import FeaturedPosts from './componts/FeaturedPosts/FeaturedPosts';
import Footer from './componts/Footer/Footer';
import Header from './componts/Header/Header';
import React from 'react';
const App = () => {
  return (
    <div className="App">
      <CustomNavbar />
      <Header />
      <FeaturedPosts />
      <Footer/>
    </div>
  );
}

export default App;
